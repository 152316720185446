import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "./App.css";
import TdkSuperApp from "./tdksa";
import MiniAppSetting from "./appsetting";
import React, { useState, useEffect } from "react";
const Home = () => {
  const [imageBase64, setImageSrc] = useState("");
  var tdkSA = new TdkSuperApp();
  useEffect(() => {
    try {
      setAppBar();
    } catch (error) {
      // alert(error);
    }
  }, []);
  const handleClick = () => {
    window.tdkSA.callHandler("myHandlerName").then(function (result) {
      alert(result.bar);
    });
  };

  const requestImage = () => {
    window.tdkSA.callHandler("requestImage").then(function (result) {
      if (result != null) {
        setImageSrc(result.image);
      }
    });
  };

  const setAppBar = () => {
    // var appSetting = new MiniAppSetting("Testing App", "CACACA", true, false);
    var appSetting = new MiniAppSetting("Testing App", "094D48", true, false);

    tdkSA.setAppBar(appSetting);
  };

  const data = [
    {
      name: "Camera",
      image: "https://cdn-icons-png.flaticon.com/512/2956/2956744.png",
      onTap: () => tdkSA.cameraCapture(),
    },
    {
      name: "Photos",
      image:
        "https://icons.iconarchive.com/icons/praveen/minimal-outline/512/gallery-icon.png",
      onTap: () => requestImage(),
    },
    {
      name: "Next Page",
      image: "https://cdn-icons-png.flaticon.com/512/279/279930.png",
      onTap: () => handleClick(),
    },
    {
      name: "AppBar",
      image:
        "https://static-00.iconduck.com/assets.00/settings-icon-2048x2046-cw28eevx.png",
      onTap: () => setAppBar(),
    },
  ];
  return (
    <div className="container">
      {data.map((item, index) => (
        <div className="grid-item" key={index} onClick={item.onTap}>
          <img src={item.image} alt={item.name} className="item-image" />
          <p>{item.name}</p>
        </div>
      ))}
      <div>
        <img
          className="imagePicked"
          src={`data:image/png;base64,${imageBase64}`}
          alt="Uint8Array Image"
        />
        <input></input>
      </div>
      <div></div>
    </div>
  );
};
export default Home;

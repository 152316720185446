export default class TdkSuperApp {
  //
  //
  setAppBar = (appSetting) => {
    window.tdkSA.callHandler("appSetting", [
      appSetting.appTitle,
      appSetting.enableBackButton,
      appSetting.colorCode,
      appSetting.centerTitle,
    ]);
  };

  cameraCapture = () => {
    window.tdkSA
      .callHandler("cameraCapture")
      .then(function (result) {
        // alert(result);
        navigator.permissions
          .query({ name: "camera" })
          .then((permissionObj) => {
            alert("Permission: " + permissionObj.onchange);
            // permission = permissionObj.state;
          })
          .catch((error) => {
            console.log("Got error :", error);
          });
      })
      .catch((error) => {
        // setImageLength(14);
        console.error("Error fetching image:", error);
      });
  };

  requestImage = () => {
    window.tdkSA.callHandler("requestImage").then(function (result) {
      if (result != null) {
        return result;
      }
    });
  };

  requestLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          alert("hehe");
          alert(latitude);
          // return {
          //   success: true,
          //   coords: { latitude, longitude },
          // };
        },
        (error) => {
          alert(false);
          return { success: false };
        }
      );
    } catch (error) {
      alert(error);
    }
  };
}

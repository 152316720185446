import React from "react";

const About = () => {
  return (
    <div>
      <h2>About Page</h2>
      <p>This is the about page content.</p>
    </div>
  );
};

export default About;
